import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import Lottie from 'react-lottie';
import animationData from '../public/assets/lottie/badges.json';

const Animatedad = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen is mobile

  // Define default options for Lottie
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  // Return null if it's mobile view to hide the component
  if (isMobile) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px',
        borderRadius: '8px',
        backgroundColor: '#fff',
        marginBottom: '30px',
        marginTop: '10px',
        flexDirection: 'column',
        textAlign: 'center',
        width: '100%',
        height: '100px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Lottie Animation */}
      <Lottie options={defaultOptions} height="100%" width="100%" style={{ position: 'absolute', top: 0, left: 0 }} />
    </Box>
  );
};

export default Animatedad;
